import type { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import type { Filters } from '@autobid/nuxt-pinia-store/types/Cars'

type Props = {
  filters?: Filters
  sortingType?: string
  currentPage: string
  route: RouteLocationNormalizedLoaded
  router: Router
}

export const updateUrlParams = async ({
  filters,
  currentPage,
  sortingType,
  route,
  router
}: Props) => {
  const { query: currentQuery } = route
  const query: Record<string, string | string[] | undefined> = {
    currentPage,
    sortingType
  }

  for (const key in filters) {
    const values = filters[key as keyof typeof filters]
    const wasCarListInitializedAlready = currentQuery.sortingType !== undefined

    // to reset previous url state when filters updated
    if (wasCarListInitializedAlready) {
      query[`${key}[]`] = undefined
    }

    for (const value in values) {
      query[`${key}[]`] = [...(query[`${key}[]`] ?? []), value]
    }
  }

  const routerFunction =
    Number(currentQuery.currentPage) !== Number(query.currentPage)
      ? 'push'
      : 'replace'

  await router[routerFunction]({
    query: {
      ...currentQuery,
      ...query
    }
  })
}
